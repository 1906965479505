<template>
<div class="contact-card">
  <div class="contact-card__img contact-card__img_top" v-if="top">
    <ImageCard :url="image"/>
  </div>
  <div class="contact-card__text">
    <h1>{{title}}</h1>
    <p>{{text}}</p>
  </div>
  <div class="contact-card__img contact-card__img_bottom" v-if="!top">
    <ImageCard :url="image"/>
  </div>
</div>
</template>

<script>
import ImageCard from "@/ui/ImageCard.vue";

export default {
  name: "ContactCard",
  components: { ImageCard },
  props:{
    image:String,
    title:String,
    text:String,
    top:Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.contact-card{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__img{
    margin-bottom: 22px;
    position: relative;
    &_top{
      width:100%;
      &:before{
        content: "";
        display: block;
        padding-top: 58%;
      }
    }
    &_bottom{
      width:60%;
      &:before{
        content: "";
        display: block;
        padding-top: 173%;
      }
    }
    @media (min-width: 320px) and (max-width: 800px){
      order: 2;
    }
  }
  &__text{
    max-width: 430px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
      @include font("Prata",20px,400);
      @include adaptiv-font(20, 13);
      margin-bottom: 30px;
      letter-spacing: 0.205em;
      text-align: center;
      text-transform: uppercase;
      max-width: 250px;
      width: 100%;
      @media (min-width: 320px) and (max-width: 1069px){
      margin-bottom: 10px;
    }

    }
    p{
      @include font("Pontano",20px,400);
      @include adaptiv-font(20, 14);
      letter-spacing: 0.205em;
      text-align: center;
      margin-bottom: 22px;
    }
    @media (min-width: 320px) and (max-width: 800px){
      order: 1;
    }
  }
}
</style>