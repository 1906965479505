<template>
<div class="contact">
  <div class="contact__title">
    <h1>
      Wonder how much your art object might be worth and how to sell it?
    </h1>
  </div>
  <div class="contact__text">
    <p>

      simply follow the steps below<br/>
      and our specialists will review your submission<br/>
      at no cost and provide preliminary estimates for items
    </p>
  </div>
  <div class="contact__box">
    <div class="contact__card">
      <ContactCard :top="true" title="Tell us about your Item" text="add dimensions, history and any documentation" :image="require('@/assets/img/animal.jpg')"/>
    </div>
    <div class="contact__card contact__card_mg-top" >
      <ContactCard title="Upload Photos" text="take front and back images of your item" :image="require('@/assets/img/tree.jpg')"/>
    </div>
  </div>
  <div class="contact__title">
    <h1>
      and estimate your art
    </h1>
  </div>
  <div class="contact__steps">
    <h1>
      Please take few minutes to fill out the form.<br/> Your answer will allow us to better meet your expectations
    </h1>
    <div class="contact__steps-box">
      <StepsContact/>
    </div>
  </div>
  <div class="contact__form">
    <Form/>
    <div class="contact__img"></div>
  </div>
  <Modal/>
</div>
</template>

<script>
import ContactCard from "@/components/ContactCard.vue";
import Form from "@/components/Form.vue";
import StepsContact from "@/ui/StepsContact.vue";
import Modal from "@/components/Modal.vue";
import { mapMutations } from "vuex";

export default {
  name: "Contact-view",
  components: { Modal, StepsContact, Form, ContactCard },
  mounted() {
    this.setMainPage("/contacts")
  },
  methods:{
    ...mapMutations({
      setMainPage:"SET_MAIN_PAGE"
    })
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.contact{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 5% 0 5%;
  @media (min-width: 320px) and (max-width: 1069px){
    padding: 20px 18px 0 18px;
  }
  &__title{
    margin-bottom: 40px;
    max-width: 1060px;
    width: 100%;
    h1{
      @include font("Prata",30px,400);
      @include adaptiv-font(30, 12);
      letter-spacing: 0.3em;
      color: $black;
      text-transform: uppercase;
      text-align: center;
    }
    @media (min-width: 320px) and (max-width: 1069px){
      margin-bottom: 20px;
    }
  }
  &__text{
    margin-bottom: 60px;
    p{
      @include font("Pontano",20px,400);
      @include adaptiv-font(20, 16);
      text-align: center;
      color: $black;
      letter-spacing: 0.09em;

    }
    @media (min-width: 320px) and (max-width: 1069px){
      margin-bottom: 40px;
    }
  }
  &__box{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 70px;
    @media (min-width: 320px) and (max-width: 800px){
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
    }
  }
  &__card{
    @media (min-width: 320px) and (max-width: 800px){
      margin-bottom: 30px;
    }
    &_mg-top{
      margin-top: 150px;
      @media (min-width: 320px) and (max-width: 800px){
        margin-top: 30px;
      }
    }
  }
  &__form{
    width: 100%;
    max-width: 844px;
    position: relative;
  }
  &__img{
    position: absolute;
    width: 30px;
    height: 294px;
    border-radius: 51px;
    box-shadow: $form-shadow;
    background-image: url("@/assets/img/sign.png");
    left: -90px;
    bottom: 282px;
    @media (min-width: 1001px) and (max-width: 1080px){
      left: -50px;
    }
    @media (min-width: 320px) and (max-width: 1000px){
      display: none;
    }
  }
  &__steps{
    margin-bottom: 90px;
    h1{
      @include font("Pontano",20px,400);
      @include adaptiv-font(20, 11);
      letter-spacing: 0.355em;
      text-transform: uppercase;
      text-align: center;
      color: #111111;
      margin-bottom: 22px;
    }
  }
  &__steps-box{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

</style>