import Vue from "vue";
import VueRouter from "vue-router";
import About from "@/views/About.vue";
import Services from "@/views/Services.vue";
import Blog from "@/views/Blog.vue";
import Contact from "@/views/Contact.vue";
import FAQ from "@/views/FAQ.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "about",
    component: About,
  },
  {
    path:"/services",
    name: "services",
    component: Services
  },
  {
    path:"/news",
    name: "news",
    component: Blog
  },
  {
    path: "/contacts",
    name: "contacts",
    component: Contact
  },
  {
    path: "/FAQ",
    name: "FAQ",
    component: FAQ
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,scrollBehavior() {
      return { x: 0, y: 0 }
  }
});

export default router;
