<template>
  <form class="form" ref="form">
    <div class="form__wrapper">
      <div class="form__section">
        <h1 class="form__label" style="margin-bottom: 45px">Category</h1>
        <div class="form__checkbox">
        <Checkbox :options="category" v-model="formData.category" name="category"/>
        </div>
      </div>
      <div class="form__section">
        <h1 class="form__label" style="margin-bottom: 45px">General</h1>
        <div class="form__input" >
          <Input label="Artist" v-model="formData.artist" name="artist"/>
        </div>
        <div class="form__input" >
          <Input label="Title of works" v-model="formData.title" name="title"/>
        </div>
        <div class="form__input" >
          <Input label="Medium / Material" v-model="formData.material" name="material"/>
        </div>
        <div class="form__input" >
          <Input label="Date of Work" v-model="formData.date" name="date"/>
        </div>
      </div>
      <div class="form__section">
        <h1 class="form__label" style="margin-bottom: 45px">Measurement</h1>
       <div class="form__checkbox" style="margin-bottom: 42px">
         <Checkbox :options="measurement" v-model="formData.measurement" name="measurement"/>
       </div>
        <div class="form__input-section">
          <div class="form__input" >
            <Input label="Framed Height" v-model="formData.framed_height" name="framed_height"/>
          </div>
          <div class="form__input" >
            <Input label="Framed Width" v-model="formData.framed_width" name="framed_width"/>
          </div>
          <div class="form__input" >
            <Input label="Unframed Height" v-model="formData.unframed_height" name="unframed_height"/>
          </div>
          <div class="form__input" >
            <Input label="Unframed Width" v-model="formData.unframed_width" name="unframed_width"/>
          </div>
        </div>
      </div>
      <div class="form__section">
        <h1 class="form__label" style="margin-bottom:45px">Upload photos</h1>
        <div class="form__input" >
         <Upload/>
        </div>
      </div>
      <div class="form__section">
        <h1 class="form__label" style="margin-bottom: 45px">Contacts</h1>
        <div class="form__input" >
          <Input label="E-mail"  v-model="formData.email" name="mail"/>
        </div>
        <div class="form__input" >
          <Input label="Name" v-model="formData.name" name="name"/>
        </div>
      </div>
      <div class="form__btn" >
        <Button :btnView="true" text="send us a letter" @click="onSubmit" />
      </div>
    </div>
  </form>
</template>

<script>
import Input from "@/ui/Input.vue";
import { mapMutations, mapState } from "vuex";
import Checkbox from "@/ui/Checkbox.vue";
import Button from "@/ui/Button.vue"
import axios from 'axios';
import Upload from "@/ui/Upload.vue";

export default {
  name: "Form-component",
  components: { Upload, Checkbox, Input,Button},
  data: () => ({
    formData:{
      category:"painting",
      artist:"",
      title:"",
      material:"",
      date:"",
      email:"",
      name:"",
      measurement:"inches",
      framed_height:"",
      framed_width:"",
      unframed_height:"",
      unframed_width:"",
      photo: "",
    },

  }),

  computed:{
    ...mapState({
      general:(state)=>state.general,
      contacts:(state)=>state.contacts,
      category:(state)=>state.category,
      measurement:(state)=>state.measurement,
      photos:(state)=>state.photos,
      size:(state)=>state.size
    }),

  },
  methods: {
    ...mapMutations({
      setModal:"SET_MODAL",
      setHideModal:"SET_HIDE_MODAL",
      setStatus:"SET_STATUS",
      setValidation:"SET_VALIDATION",
      deletePhotos:"SET_PHOTOS_DELETE"
    }),

    cleanForm(){
      this.formData={
        category:"painting",
          artist:"",
          title:"",
          material:"",
          date:"",
          email:"",
          name:"",
          measurement:"inches",
          framed_height:"",
          framed_width:"",
          unframed_height:"",
          unframed_width:"",
      }
      this.deletePhotos()
    },
    async onSubmit() {
      if(this.formData.email==="" || this.formData.name==="" ||
        this.formData.date==="" || this.formData.category==="" ||
        this.formData.title==="" || this.formData.unframed_width==="" ||
        this.formData.unframed_height==="" || this.formData.framed_width==="" ||
        this.formData.framed_height==="" || this.formData.artist==="" ||
        this.formData.material==="" || this.formData.measurement===""
      ){
        this.setValidation()
        this.setStatus("fill in the fields!")
        this.setModal()
        setTimeout(() => {
          this.setHideModal()
        }, 10000);
      }else {
        const TOKEN = '6152754277:AAE4mu6wKTJlgnqGzPiq6IspYCuL2Q3hQIM';
        const CHAT_ID = '-1001989113162';
        const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
        const IMG_URL_API = `https://api.telegram.org/bot${TOKEN}/sendDocument`;
        let message = `<b>Application for the company "Russian art consulting"</b>
        <b>Hello, my name is </b> ${this.formData.name}
        <b>Email: </b>${this.formData.email}
        <b>Category: </b>${this.formData.category}
        <b>Artist: </b>${this.formData.artist}
        <b>Title of works: </b>${this.formData.title}
        <b>Medium / Material: </b>${this.formData.material}
        <b>Date of Work: </b>${this.formData.date}
        <b>Measurement: </b>${this.formData.measurement}
        <b>Framed Height: </b>${this.formData.framed_height}
        <b>Framed Width: </b>${this.formData.framed_width}
        <b>Unframed Height: </b>${this.formData.unframed_height}
        <b>Unframed Width: </b>${this.formData.unframed_width}
`
        await axios.post(URI_API,{
          chat_id:CHAT_ID,
          parse_mode:'html',
          text:message
        }).then(()=>{
          this.setStatus("success!")
          this.setModal()
        }).catch(()=>{
          this.setStatus("error!")
          this.setModal()
        }).finally(()=>{
        })
        const formDataImg = new FormData();
        formDataImg.append('chat_id',CHAT_ID)
        await this.photos.forEach((el)=>{
          formDataImg.delete('document')
          formDataImg.append('document',el)
          axios.post(IMG_URL_API,formDataImg,{
            headers:{
              'Content-Type':'multipart/form-data'
            }
          }).then(()=>{
            this.setStatus("success!")
            this.setModal()
          }).catch(()=>{
            this.setStatus("error!")
            this.setModal()
          }).finally(()=>{
            this.cleanForm()
          })
        })
      }

    },
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.form{
  width: 100%;
  &__label{
    @include font("Prata",16px,400);
    @include adaptiv-font(16, 14);
    color: $black;
    letter-spacing: 0.1em;
    width: 100%;
  }

  &__checkbox{
    width: 100%;
    max-width: 214px;
  }

  &__input{
    width: 100%;
  }

  &__section{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__input-section{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 632px;
    width: 100%;
    @media (min-width: 320px) and (max-width: 515px){
      flex-direction: column;
      max-width: none;
    }
    .form__input{
      max-width: 217px;
      width: 100%;
      @media (min-width: 320px) and (max-width: 515px){
        max-width: none;
      }
    }
  }
  &__btn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 45px;
  }
}

</style>