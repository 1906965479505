<template>
  <div class="about">
    <div class="about__card">
      <GreetingCard/>
    </div>
    <div class="about__img">
      <ImageCard styleType="round" :url="require('@/assets/img/paint_about.png')"/>
    </div>
    <div class="about__steps">
      <StepsCard/>
    </div>
    <div class="about__btn">
      <Button text="send us a letter"/>
    </div>
  </div>

</template>

<script>
import GreetingCard from "@/components/GreetingCard.vue";
import ImageCard from "@/ui/ImageCard.vue";
import StepsCard from "@/ui/Steps.vue";
import Button from "@/ui/Button.vue";

export default {
  name: "About-view",
  components: { Button, StepsCard, ImageCard, GreetingCard }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.about{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:78px 5% 0 5%;
  @media (min-width: 320px) and (max-width: 900px){
    padding:26px 18px 0 18px;
  }
  &__img{
    margin-top: 96px;
    position: relative;
    width: 100%;
    &:before{
      content: "";
      display: block;
      padding-top: 28%;
    }
    @media (min-width: 320px) and (max-width: 900px){
      margin-top: 36px;
    }
  }
  &__card{
    width: 100%;
    max-width: 996px;
  }
  &__steps{
    margin-top: 96px;
    max-width: 1034px;
    width: 100%;
    @media (min-width: 320px) and (max-width: 900px){
      margin-top: 32px;
    }
  }
  &__btn{
    margin-top: 106px;
    @media (min-width: 320px) and (max-width: 900px){
      margin-top: 42px;
    }
  }
}
</style>