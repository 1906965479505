<template>
  <button class="btn" :class="preview ? 'btn_preview' : 'btn_send'"  @click="sendEvent">
    <router-link  class="btn__text" to="/contacts">
      {{ text }}
    </router-link>
  </button>

</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Button-view",
  props:{
    text:String,
    preview:Boolean,
    btnView:Boolean
  },
  methods: {
    ...mapMutations({
      setMainPage:"SET_MAIN_PAGE"
    }),
    sendEvent(e) {
      e.preventDefault()
      this.$emit('click');
    },
  },


};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: inherit;
  &_preview{
    height: 28px;
    box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.6), 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
    border: 0.5px solid  rgba(32,32,32, 0.3);;
    border-radius: 19px;
    width: 100%;
    @media (min-width: 320px) and (max-width: 800px){
      height: 26px;
    }
    .btn__text{
      @include font("Pontano",18px,400);
      @include adaptiv-font(18, 14);
      background-color: rgba(0, 0, 0, 0.7);
      color: transparent;
      text-shadow: 0px 1px 1px #BCBCBC;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
    }
  }
  &_send{
    border-radius: 0 0 50% 50% /  0 0 100% 100%;
    height: 70px;
    width: 140px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.7) , 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  ;

    .btn__text{
      opacity: 0.6;
      max-width: 68px;
      width: 100%;
      text-align: center;
      @include font("Pontano",14px,400);
      @include adaptiv-font(14, 13);
      background-color: rgba(0, 0, 0, 0.7);
      color: transparent;
      text-shadow: 0px 1px 1px #BCBCBC;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      text-transform: uppercase;
      margin: 0;
      display: flex;
      align-items: end;

    }

  }
}
</style>