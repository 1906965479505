<template>
<div class="footer">
  <div class="footer__text">
    <p>(C) 2023 Russian Art Consulting</p>
  </div>
  <div class="footer__logo">
    <div class="footer__img">
      <img alt="logo" src="@/assets/img/cinoa.png"/>
    </div>
    <div class="footer__img">
      <img alt="logo" src="@/assets/img/icaad.png"/>
    </div>
  </div>
  <ul class="footer__links">
    <li class="footer__item"><router-link class="footer__link" to="/FAQ">
      <img alt="icon" src="@/assets/svg/FAQ.svg"/>
    </router-link></li>
    <li class="footer__item"><a class="footer__link" href="mailto:RussianArtConsulting@gmail.com">
      <img alt="icon" src="@/assets/svg/mail.svg"/>
    </a></li>
  </ul>
</div>
</template>

<script>
export default {
  name: "Footer-view"
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  margin-bottom: 54px;
  margin-top: 62px;
  padding: 0 5% 0 5%;
  @media (min-width: 320px) and (max-width: 1069px){
    padding: 20px 18px 0 18px;
  }
  @media (min-width: 320px) and (max-width: 508px){
    flex-wrap: wrap;
  }
  &__logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 162px;
    width: 100%;
    @media (min-width: 320px) and (max-width: 508px){
      order: 1;
      justify-content: center;
      max-width: none;
    }
  }
  &__links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 86px;
    width: 100%;
    .footer__item{
      cursor: pointer;
      img{
        width: 32px;
      }
    }
    @media (min-width: 320px) and (max-width: 508px){
      order: 3;
    }
  }
  &__text{
    p{
      @include font("Prata",12px,400);
      @include adaptiv-font(12, 10);
      color: $black;
      opacity: 0.5;
    }
    @media (min-width: 320px) and (max-width: 508px){
      order: 2;
    }
  }
}
</style>