<template>
<div class="steps">
  <div class="steps__title">
    <p>
      estimate your art for free
    </p>
  </div>
  <ul class="steps__list">
    <li class="steps__item" :class="[secondary?'steps__item_line':'steps__item_column']">
      <p class="item__number">1</p>
      <div class="item__section">
        <p class="item__title item__title_left">Tell us about your Item</p>
        <p class="item__text item__text_left"> add dimensions, history and any documentation</p>
      </div>
    </li>
    <li class="steps__item" :class="[secondary?'steps__item_line':'steps__item_column']">
      <p class="item__number">2</p>
      <div class="item__section">
        <p class="item__title">Upload Photos</p>
        <p class="item__text">take front and back images of your item</p>
      </div>
    </li>
    <li class="steps__item" :class="[secondary?'steps__item_line':'steps__item_column']">
      <p class="item__number">3</p>
      <div class="item__section">
        <p class="item__title item__title_left">suggest the preferable way of contact</p>
        <p class="item__text item__text_left">our team will follow up with you shortly</p>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: "StepsCard",
  props:{
    secondary:Boolean,
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.steps{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  &__title{
    max-width: 522px;
    width: 100%;
    text-align: center;
    margin-bottom: 70px;
    @media (min-width: 320px) and (max-width: 1182px){
      margin-bottom:28px ;
    }
    p{
      @include font("Prata",30px,400);
      @include adaptiv-font(30, 17);
      text-transform: uppercase;
      letter-spacing: 0.365em;
    }
  }
  &__list{
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (min-width: 320px) and (max-width: 800px){
      flex-direction: column;
      align-items: center;
    }

  }
  &__item{
    display: flex;
    align-items: center;
    width: 100%;
    @media (min-width: 320px) and (max-width: 800px){
      margin-bottom:56px ;
      &:last-child{
        margin-bottom:0 ;
      }
    }
    p{
      text-align: center;
      color: $black;
    }
    &_line{
      justify-content: space-between;
      max-width: 270px;
      .item__section{
        max-width: 216px;
        width: 100%;
        .item__title_left{
          text-align: start;
        }
        .item__text_left{
          text-align: start;
        }
      }
      @media (min-width: 320px) and (max-width: 800px){
        flex-direction: column;
        .item__section{
          max-width: 220px;
          .item__title_left{
            text-align: center;
          }
          .item__text_left{
            text-align: center;
          }
        }
        .item__number{
            margin-bottom:28px ;

        }
      }
    }
    &_column{
      flex-direction: column;
      max-width: 220px;
      .item__number{
        margin-bottom:78px ;
        @media (min-width: 320px) and (max-width: 1182px){
          margin-bottom:28px ;
        }
      }
    }
  }
  .item{
    &__number{
      @include font("Praise",56px,400);
      @include adaptiv-font(56, 22);
      text-transform: uppercase;
    }

    &__title{
      margin-bottom:20px;
      @include font("Prata",16px,400);
      letter-spacing: 0.205em;
      text-transform: uppercase;
      @include adaptiv-font(16, 14);
    }

    &__text{
      @include font("Pontano",16px,400);
      letter-spacing: 0.205em;
      @include adaptiv-font(16, 14);
    }
  }

}
</style>