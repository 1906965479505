import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    open:false,
    status:"",
    validation:true,
    mainPage:"/",
    menu:[
      {
        src: require('@/assets/img/about.png'),
        id:"1",
        path:"/",
        title:"about us",
        style:"about"
      },
      {
        src:require('@/assets/img/services.png'),
        id:"2",
        path:"/services",
        title:"services",
        style:"service"
      },
      {
        src:require('@/assets/img/blog.png'),
        id:"3",
        path:"/news",
        title:"info blog",
        style:"blog"
      },
      {
        src:require('@/assets/img/contact.png'),
        id:"4",
        path:"/contacts",
        title:"contact us",
        style:"contact"
      },
      {
        src:require('@/assets/img/hunter.jpg'),
        id:"25",
        path:"/FAQ",
        title:"FAQ",
        style:"contact"
      }
    ],
    general:[
      {
        id:"5",
        label:"Artist",
        name:"Artist"
    },
      {
        id:"6",
        label:"Title of works",
        name:"title"
      },
      {
        id:"7",
        label:"Medium / Material",
        name:"material"
      },
      {
        id:"8",
        label:"Date of Work",
        name:"date"
      },
    ],
    contacts:[
      {
        id:"9",
        label:"E-mail",
        name:"mail",
      },
      {
        id:"10",
        label:"Name",
        name:"name"
      },
    ],
    size:[
      {
        id:"11",
        label:"Framed Height",
        name:"framed_height"
      },
      {
        id:"12",
        label:"Framed Width",
        name:"framed_width"
      },
      {
        id:"13",
        label:"Unframed Height",
        name:"unframed_height"
      },
      {
        id:"14",
        label:"Unframed Width",
        name:"unframed_width"
      },
    ],
    photos:[],
    measurement:[
      {
        id:"15",
        name:"inches",
        label:"Inches",
        checked:"true"
      },
      {
        id:"16",
        name:"centimeters",
        label:"Centimeters",
        checked:"false"
      },
      {
        id:"17",
        name:"millimeters",
        label:"Millimeters",
        checked:"false"
      },
      {
        id:"18",
        name:"meters",
        label:"Meters",
        checked:"false"
      },
    ],
    category:[
      {
        id:"19",
        label:"Painting",
        name:"painting",
        checked:true
      },{
        id:"20",
        label:"Graphics",
        name:"graphics",
        checked:false
      },{
        id:"22",
        label:"Jewelry",
        name:"jewelry",
        checked:false
      },{
        id:"23",
        label:"Porcelain",
        name:"porcelain",
        checked:false
      },{
        id:"24",
        label:"Other",
        name:"other",
        checked:false
      }
    ]
  },
  getters: {},
  mutations: {
    SET_MODAL(state){
      state.open=true
    },
    SET_HIDE_MODAL(state){
      state.open=false
    },
    SET_STATUS(state,status){
      state.status=status
    },
    SET_VALIDATION(state){
      state.validation=false
    },
    SET_MAIN_PAGE(state,page){
      state.mainPage=page
    },
    SET_PHOTOS(state,photos){
      state.photos=state.photos.concat(photos)
    },
    SET_PHOTOS_DELETE(state){
      state.photos=[]
    }
  },
  actions: {},
  modules: {},
});
