<template>
<div class="preview">
  <div class="preview__img" :style="`--my-src: url(${url})`" />
  <div class="preview__title" :class="objectStyle">
    <p>
      {{title}}
    </p>
  </div>
</div>
</template>

<script>
export default {
  name: "preview-item",
  props:{
    url:String,
    title:String,
    styleType:String
  },
  computed: {
    objectStyle() {
      return {
       preview__title_about: this.styleType === 'about',
        preview__title_service: this.styleType === 'service',
        preview__title_blog: this.styleType === 'blog',
        preview__title_contact: this.styleType === 'contact',
      };
    },
  },

};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.preview{
  width: 100%;
  position: relative;
  max-height: 438px;
  height: 100%;

  &::before {
    content: "";
    display: block;
    padding-top: 37%;
  }

  &__img{
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: 438px;
    border-radius: 11px;
    background-image: var(--my-src);
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__title{
    position: absolute;
    bottom: -1px;
    height:14%;
    width: 12%;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    background-color: $white;
    box-shadow: $title-shadow;
    display: flex;
    justify-content: center;
    @media (min-width:601px) and (max-width: 800px){
      height:20%;
      width: 18%;
    }
    @media (min-width: 320px) and (max-width: 600px){
      height:23%;
      width: 21%;
    }
    p{
      @include font("Pontano",24px,400);
      @include adaptiv-font(18, 12);
      background-color: rgba(0, 0, 0, 0.7);
      color: transparent;
      text-shadow: 0px 1px 1px #BCBCBC;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      text-transform: uppercase;
      margin: 0;
      display: flex;
      align-items: end;
      text-align: center;

    }
    &_about{
      right:40%;
    }
    &_service{
      right:27%;
    }
    &_blog{
      right:20%;
    }
    &_contact{
      right:12%;
    }
  }

}

</style>