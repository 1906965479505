<template>
  <div class="greeting">
    <div class="greeting__section">
      <div class="greeting__img">
        <div class="greeting__box"/>
      </div>
      <div class="greeting__btn">
        <Button preview=true text="Contact us"/>
      </div>
    </div>
    <div class="greeting__text">
      <p>Russian Art undergoes its ups and downs on the world market. But it will never lose
        a bit of its value for the experts and collectors. Russian Art Consulting was established with the purpose of providing a truly bespoke and high quality Russian art advisory
        and buy out service.</p>
      <p>
        We give free of charge online advisory, estimate and buy pieces of Russian fine art worldwide.
        For the last thirty years our team
        is working in a field of Russian antiques trading, regularly participating
        in magistral  Russian and international displays. We manage the logistics  &
        storage centers in the USA, Finland, Germany, and Armenia.</p>
      <p>
        And finally, we possess an extensive network of contacts among Moscow
        and Saint-Petersburg art experts dealers and collectors.
        This advantage lets us not only sell your pieces
        of fine art to the right hands, but also give you fair prices for every item.
      </p>
    </div>
  </div>
</template>

<script>
import Button from "@/ui/Button.vue";

export default {
  name: "Greeting-card",
  components: { Button },
  data:()=>({
    width: 0
  }),
  created() {
    this.updateWidth()
    window.addEventListener('resize', this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.greeting{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media (min-width: 320px) and (max-width: 900px){
    flex-direction: column-reverse;
  }

  &__section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 394px;
  }

  &__btn{
    max-width: 182px;
    width: 100%;
    margin-top: 42px;
    @media (min-width: 320px) and (max-width: 800px){
      margin-top: 15px;
      max-width: 180px;
    }
  }

  &__img{
    border-radius: 50%;
    max-width: 394px;
    width: 100%;
    position: relative;
    @media (min-width: 320px) and (max-width: 1182px){
      max-width: 80%;
    }
    &::before {
      content: "";
      display: block;
      padding-top: 3%;
    }
  }
  &__box{
    background-image: url("@/assets/img/greeting.jpg");
    width: 100%;
    padding-bottom: 102%;
    border-radius: 50%;
    box-shadow: inset 1px 6px 0px #121111;
    background-position: 50% -10%;
    background-size: cover;
  }
  &__text{
    max-width: 546px;
    width: 100%;
    margin-top: 30px;
    @media (min-width: 320px) and (max-width: 800px){
      max-width: none;
      margin-bottom: 20px;
    }
    @media (min-width: 800px) and (max-width: 1182px){
      max-width: 546px;
      margin-bottom: 20px;
    }
    p{
      @include font("Prata",16px,400);
      color: $black;
      margin-bottom: 16px;
      text-indent: 30px;
      @include adaptiv-font(16, 16);
      line-height: 32px;
      @media (min-width: 320px) and (max-width: 600px){
        line-height: 28px;
      }
    }
  }
}

</style>