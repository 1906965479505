<template>
<div class="input">
  <label class="input__label" :class="!inputValid?'input__label_error':''">
    <span>{{label}}</span>
    <input @change="onChange"  v-model="value" :name="name" @blur="setValidation"/>
  </label>
</div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Input-component",
props:{
    label:String,
    name:String,
},
  data(){
    return {
      value: "",
      inputValid: true,
    };
  },
  computed:{
    ...mapState({
      validation:(state)=>state.validation,
      status:(state)=>state.status
    }),

  },
  methods: {
    onChange() {
      this.$emit('input', this.value);
    },
    setValidation(){
      if(this.value===''){
        this.inputValid=false
      }else if(name==='email') {
console.log("")
      }else {
        this.inputValid=true
      }
    }
  },
  watch:{
    validation(){
      if(!this.validation){
        this.setValidation()
      }
    },
    status(){
      if (this.status==="success!"){
        this.value=""
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.input{
  width: 100%;
  margin-bottom: 28px;

&__label{
  position: relative;
  width: 100%;
 input{
   height:32px;
   border-radius: 5px;
   background-color: $input-gray;
   box-shadow:$input-shadow;
   border: none;
   width: 100%;
   color: $light-black;
   padding-left: 22px;
 }
  span{
    position: absolute;
    top:-26px;
    left: 26px;
    @include font("Prata",12px,400);
    @include adaptiv-font(12, 12);
    color: $light-black;
  }
}
}
.input__label_error{
  input {
    border: 1px solid #fa4b4b;
  }
}
</style>