<template>
  <div class="blog">
    <div class="blog__card">
      <div class="card__img card__img_desktop">
        <div class="card__img_box"></div>
      </div>
      <div class="card__text">
        <h2>Oil painting attribution and expertise. Stages and particularities</h2>
        <p>
          Trade in works of expensive authors turns art lovers into investors and obliges them to
          be careful. Forgeries are the major headache for a collector. Especially those accompanied by
          expertise. Such in the last half century have penetrated the antiques market and even museums.
          Can it be that this is the fault of unscrupulous experts, thirsty for profit?</p>
          <p>
          Few types of experts
          can be conditionally distinguished in the European antiques market. Researchers of cultural
          institutions can be classified as the first type. Scientific degree and access to resourceful
          museum funds makes such specialists owners of the most profound databases on particular
          circles of painters. The other type is experts of big auction houses. Due to the existing dynamics
          auction house experts are often generalists. We may place gallery experts as a third type - those
          - in the best case - are narrowly focused specialists who sometimes appear no less proficient
          than museum researchers. The institution of art expertise in Russia exists since 1965. Now a
          collector who wants to authenticate painting has a choice - to trust state experts or private
          ones.
        </p>
        <div class="card__img card__img_mobile">
          <div class="card__img_box"></div>
        </div>
        <p>
          The circle of private experts is not so large, and all recognized state experts are well
          known to market participants. When buying a painting, they will go more likely to these names.
          According to Olga Glebova, an expert, forgeries, that is, criminal offenses among experts, are
          rather exceptional cases. In fact, the confirmation of "new" unknown paintings by recognized
          masters often happens due to a sincere mistake. After all, with the level of science about fakes,
          the level of fakes automatically grows. The old masters may have provided their canvases for
          pupils to copy.
        </p>
        <p>
          When a copy of a painting by Aivazovsky has been painted under the watchful eye of
          Aivazovsky himself and it gets to your hands, a generalist expert might make a mistake. When it
          comes to twentieth century paintings, which are sometimes easy to reproduce, it is not only the
          expert that is expected to make a mistake. The chemical examination of pigments might easily
          confuse the chemist.

        </p>
        <p>
          You may be surprised but it is much easier to learn about the palette of the
          Peredvizhniks than about that of the twentieth century masters. The dye industry of the XX
          century appears to be understudied and poorly classified. How does a standard art attributing
          and examining look like and is it a standard procedure?
        </p>
      </div>
    </div>
    <div class="blog__card blog__card_reverse">
      <div class="card__img">
        <ImageCard :url="require('@/assets/img/icon.jpg')"/>
      </div>
      <div class="card__text">
        <h2>We will explain</h2>
        <p>
          The first stage of the examination has always been a stylistic visual analysis.
          The examiner evaluates a painting according to clear criteria developed by art historians. First of
          all, he tries to attribute the work to the authorship of a particular master. He compares the
          signature, the manner and colouring, and the thematic range of author’s creative work to the
          given piece. Perhaps the specialist will select a number of references to the author's works, in
          which an artistic search on a similar theme will turn evident. Such data alone is the basis for an
          estimate.
        </p>
        <p>
          Both visual attribution and technical-and-process expertise are based on comparisons
          with etalons, i.e. other works by the artist, with a database of X-ray films taken from the artist's
          etalon works, with a graphological database (samples of his signatures and handwriting), and
          with a database of paints used by the artist. And since most works of art in Russia are in
          museums, it is logical that the reference databases for their various characteristics were formed
          in museums or in state organisations dealing with restoration and expertise.
          And this is only the beginning of the appraisal. There are a number of methods that can
          detect what is hidden from the naked eye. And the first of these are infrared and ultraviolet light.
        </p>
        <p>
          UV and infra red lamps have become a handy method for antique dealer and restorer.
          While formal examination expert takes a snapshot of the painting in each light. The
          spectra reveal islands of interference with the covering layer - varnish, varnish or glaze - which
          have been well camouflaged by the previous restorer. Infrared, in particular, will show a pencil
          sketch or a dimensional grid in places where the paint is not laid thickly. One specialist can tell
          the difference between a painting by Aivazovsky or Shishkin and a student's copy just by looking
          at the preliminary sketch under the oil.
        </p>
        <p> Once the paint and varnish layers have been examined, you can take a closer look at
          the primer and substrate. X-ray examination will take care of this. Such a process will already
          require interaction with institutions of expertise and restoration. The headline places in Russia
          where it is possible to X-ray a painting (or, say, an ancient head) are the following:
        </p>
        <ol>
          <li>Ilya Repin Art Attribution Centre (Moscow)</li>
          <li>The Russian National Artistic Research and Restoration Center of Igor Grabar (Moscow)</li>
          <li>The Tretyakov National Scientific Research Institute (Moscow)</li>
          <li>The Russian Museum (Saint-Petersburgh)</li>
          <li>Independent Scientific Research Center of Expertise of Alexander Benois
            (Saint-Petersburgh)</li>
        </ol>
        <p>
          An X-ray will reveal the structure of the pictorial base and the features of the application of the
          primer and its condition. In the case of an antique head, the X-ray will allow the cavity inside to
          be examined for patina: no one patinates fakes from the inside. This examination is also
          provided in the form of a photograph as is the examination of the texture below.
        </p>
        <p>
          The scientist examines the texture under a microscope and takes a macro-photograph. It is
          revealed that Aivazovsky's mountains are painted with a stiff brush, the craquelure corresponds
          to the similar on the museum originals and the like. The techniques of making paints and their
          composition have changed in the course of history. The experience of research on painting,
          especially 20th century painting, shows that the age of an unknown painting can be established
          only by examining the materials used in its study. Modern knowledge of artistic materials is
          sufficient for a qualified technological examination of works ... until the end of the 19th century.
        </p>
        <p>
          Paradoxically, with pigments from the twentieth century everything is more complicated. Information about
          the introduction and global distribution of modern pigments has not been made publicly
          available, and has certainly not been classified. It is not known when they were first used in the
          USSR or what was the composition of the binding agent of soviet paints.
          </p>
        <p>
          We hope that this note
          has helped you to appreciate how complex and tricky the question of authenticity is. You may
          also see that the expertise lies in clear scientific criteria and inquisitive doubts of scientists. We
          have described the most popular but not all of the methods of physical and chemical
          examination. With the development of linked scientific areas and with each new study the
          expert bases renew, undoing old colleagues’ blunders and becoming more powerful.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCard from "@/ui/ImageCard.vue";

export default {
  name: "Blog-view",
  components: { ImageCard }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.blog{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 5% 0 5%;
  @media (min-width: 320px) and (max-width: 1069px){
    padding: 20px 18px 0 18px;
  }
  &__card{
    width: 100%;
    margin-bottom: 60px;
    @media (min-width: 320px) and (max-width: 600px){
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
    .card{
      &__img{
        width: 29%;
        float: left;
        margin-right: 39px;
        margin-bottom: 10px;
        @media (min-width: 320px) and (max-width: 600px){
          float: none;
          width: 70%;
          margin-bottom: 30px;
          margin-right: 0;
        }
        p{
          @include font("Prata",14px,400);
          letter-spacing: 0.205em;
          color: $black;
          text-align: end;
          margin-top: 48px;
        }
        &_box{
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          border-radius: 59px;
          box-shadow: inset 3px 4px 0px rgba(0, 0, 0, 0.55);
          background-position: 50% 14%;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url("@/assets/img/women.jpg");
        }
        &_desktop{
          height: 298px;
          @media (min-width: 601px) and (max-width: 800px){
            height: 168px;
            width: 36%;
          }
          @media (min-width: 320px) and (max-width: 600px){
            display: none;
          }
        }
        &_mobile{
          display: none;
          height: 298px;
          @media (min-width: 320px) and (max-width: 600px){
            display: block;
            align-self: center;
          }
        }
      }
      &__text{
        width: 100%;
        @media (min-width: 320px) and (max-width: 600px){
          display: flex;
          flex-direction: column;
        }
        h1{
          text-align: center;
          @include font("Prata",30px,400);
          @include adaptiv-font(30, 16);
          color: $black;
          margin-bottom: 12px;
          text-transform: uppercase;
        }
        h2{
          letter-spacing: 0.3em;
          @include font("Prata",30px,400);
          @include adaptiv-font(30, 16);
          color: $black;
          margin-left: 22px;
          text-transform: uppercase;
          margin-bottom: 26px;
          @media (min-width: 320px) and (max-width: 600px){
            text-indent: 0;
            text-align: center;
            margin-top: 20px;
          }
        }
        p{
          @include font("Prata",16px,400);
          @include adaptiv-font(18, 15);
          color: $black;
          line-height: 32px;
          text-indent: 60px;
          @media (min-width: 320px) and (max-width: 800px){
            text-indent: 20px;
          }
          margin-bottom: 10px;
        }

        ol{
          margin-left: 102px;
          width: 80%;
          @media (min-width: 601px) and (max-width: 900px){
            margin-left: 60px;
          }
          @media (min-width: 320px) and (max-width: 600px){
            margin-left: 20px;
          }
        }

        li{
          @include font("Prata",16px,400);
          @include adaptiv-font(16, 15);
          color: $black;
          line-height:26px;
        }
        .card__paragraph{
          text-indent: 0;
        }
        @media (min-width: 320px) and (max-width: 800px){

        }
      }
    }
    &_reverse{
      float: right;
      .card {
        &__img {
          float: right;
          width: 44%;
          margin-left: 12px;
          margin-bottom: 16px;
          margin-right: 0;
          position: relative;
          &:before{
            content: "";
            display: block;
            padding-top: 87%;
          }
          @media (min-width: 320px) and (max-width: 600px){
            float: none;
            padding-left: 0;
            width: 80%;
          }
        }
      }
      @media (min-width: 320px) and (max-width: 600px){
        flex-direction: column;
        align-items: center;
      }
    }
  }
  &__text{
    @include font("Prata",16px,400);
    @include adaptiv-font(16, 14);
    color: $black;
    line-height:29px
  }
}
</style>