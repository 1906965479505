<template>
  <div class="link" @click="getCurrentPage(id)" v-if="id!='25'">
    <router-link :class="[(active===id)?'link__text link__text_active':'link__text']"   :to="path">{{title}}</router-link>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "link-item",
  props:{
    title:String,
    path:String,
    id:String,
    active:String
  },
  computed:{
    ...mapState({
      mainPage:(state)=>state.mainPage
    }),

  },
  methods:{
    ...mapMutations({
      setMainPage:"SET_MAIN_PAGE"
    }),
    getCurrentPage(currentId) {
      this.setMainPage(this.path)
      this.$emit('click', currentId)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";

.link{
  &__text {
  color:$black;
  text-transform: uppercase;
  @include font("Pontano",18px,400);
  &_active{
    text-shadow: $link-shadow;
  }
  &:hover{
    text-shadow: $link-shadow;
  }
  }
}

</style>