<template>
  <div>
    <div class="service">
      <div class="service__card">
        <div class="card__text card__text_consultancy">
          <h1>consultancy</h1>
          <p>
            Russian Art Consulting gives free of charge online advisory on pieces of Russian fine art worldwide.
            Our experts and art dealers are well within two international associations aiming to protect, preserve and promote art and antiques through encouraging the international сirculation of works of art and antiques for the trade as well as for the private
            and public collectors.
          </p>
          <p>
            For the last thirty years, our team is working in a field of Russian antiques trading, regularly taking part in magisterial Russian
            and international displays.
            We possess an extensive network of contacts between Moscow and Saint Petersburg.
            This advantage lets us not only attribute your item, but risk buying it out fair and square.
          </p>
        </div>
        <div class="card__img card__img_consultancy">
          <ImageCard styleType="round" :url="require('@/assets/img/consultant.jpg')"/>
        </div>
      </div>
      <div class="service__card service__card_reverse">
        <div class="card__img card__img_estimation">
          <ImageCard styleType="round" :url="require('@/assets/img/estimation.jpg')"/>
        </div>
        <div class="card__text card__text_estimation">
          <h1>estimation</h1>
          <p>
            Russian Art Consulting estimates pieces of Russian fine art professionally for free.
            We regularly take part in Russian and international displays and it is important for us to get the precious collection rotating.
          </p>
          <p>
            Your request gives us a chance to collect a new highly demanded items and find the worthy and generous hands for it.
            And your award here is free consultancy and estimation.
          </p>
        </div>
      </div>
      <div class="service__card ">
        <div class="card__text card__text_buyout">
          <h1>buyout</h1>
          <p style="margin-bottom: 10px">
            Sell privately at your own time!
          </p>
          <p>
            We manage the logistics & storage centers in the USA, Finland, Germany and Armenia.
            And we possess an extensive network of contacts among Moscow and Saint Petersburg art collectors.
            This advantage lets us not only sell your pieces of fine art in the right hands but also give you agreeable prices for every item.
          </p>
          <p>
            Our experts and art dealers are well within two international associations - CINOA and ICAAD - aiming to protect,
            preserve and promote art and antiques through encouraging the international circulation of works of art for the trade.
          </p>
        </div>
        <div class="card__img card__img_buyout">
          <ImageCard styleType="round" :url="require('@/assets/img/buyout.jpg')"/>
        </div>
      </div>
    </div>
    <div class="service__img">
      <ImageCard styleType="none" :url="require('@/assets/img/eyes.png')"/>
    </div>
    <div class="service">
      <div class="service__steps">
        <StepsCard secondary=true />
      </div>
      <div class="service__btn">
        <Button text="send us a letter"/>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCard from "@/ui/ImageCard.vue";
import StepsCard from "@/ui/Steps.vue";
import Button from "@/ui/Button.vue";
import { mapMutations } from "vuex";

export default {
  name: "Services-view",
  components: { StepsCard, ImageCard,Button },
  data:()=>({
    width: 0
  }),
  created() {
    window.addEventListener('resize', this.updateWidth);
  },
  methods:{
    ...mapMutations({
      setMainPage:"SET_MAIN_PAGE"
    }),
    changeLink() {
      this.setMainPage("/contacts")
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.service{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 5% 0 5%;
  @media (min-width: 320px) and (max-width: 1069px){
    padding: 36px 18px 0 18px;
  }
  & :first-child{
    @media (min-width: 320px) and (max-width: 800px){
      .card__text{
        h1{
          text-align: end;
          margin-right: 50px;
        }
      }
    }
  }
  & :last-child{
    @media (min-width: 320px) and (max-width: 800px){
      .card__text{
        h1{
          text-align: end;
          margin-right: 50px;
        }
      }
    }
  }
  &__card{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    @media (min-width: 320px) and (max-width: 800px){
      flex-direction: column;
      align-items: center;
      margin-bottom: 22px;
      .card__img{
        align-self: end;
      }
    }
    .card{
      &__img{
        width: 48%;
        position: relative;
        p{
          @include font("Prata",14px,400);
          letter-spacing: 0.205em;
          color: $black;
          text-align: end;
          margin-top: 24px;
          @media (min-width: 320px) and (max-width: 800px){
            display: none;
          }
        }
        &_consultancy{
          max-height: 299px;
          max-width: 440px;
          width: 100%;
          height: 100%;
          align-self: center;
          &:before{
            content: "";
            display: block;
            padding-top: 53%;
          }
          @media (min-width: 1080px) and (max-width: 1208px){
            max-width: 440px;
          }
          @media (min-width: 801px) and (max-width: 1079px){
            max-width: 406px;
            margin-left: 20px;
          }
          @media  (max-width: 800px){
            max-width: 294px;
            align-self: end;
            margin-top: 20px;
            margin-right: 30px;
          }
          @media (min-width: 320px) and (max-width: 600px){
            max-width: 137px;
            max-height: 75px;
          }
        }
        &_estimation{
          max-height: 307px;
          max-width: 478px;
          width: 100%;
          height: 100%;
          align-self: center;
          &:before{
            content: "";
            display: block;
            padding-top: 64%;
          }
          @media (min-width: 1000px) and (max-width: 1208px){
            max-width: 410px;
          }
          @media (min-width: 801px) and (max-width: 999px){
            max-width: 360px;
            margin-right: 20px;
          }
          @media (max-width: 800px){
            margin-top: 20px;
            max-width: 294px;
            max-height: 150px;
            align-self: start;
            margin-left: 30px;
          }
          @media (min-width: 320px) and (max-width: 600px){
            max-width: 137px;
            max-height: 75px;
          }
        }
        &_buyout{
          max-height: 313px;
          max-width: 524px;
          width: 100%;
          height: 100%;
          align-self: center;
          &:before{
            content: "";
            display: block;
            padding-top: 65%;
          }
          @media (min-width: 801px) and (max-width: 1208px){
            max-width: 452px;
            margin-left: 20px;
          }
          @media  (max-width: 800px){
            max-width: 282px;
            margin-top: 20px;
            max-height: 150px;
            align-self: end;
            margin-right: 30px;
          }
          @media (min-width: 320px) and (max-width: 600px){
            max-width: 137px;
            max-height: 75px;
          }
        }
      }
      &__text{
        max-width: 526px;
        width: 100%;
        position: relative;
        @media (min-width: 801px) and (max-width: 1000px){
          max-width: 468px;
        }
        @media (min-width: 320px) and (max-width: 800px){
          max-width: none;
        }
        h1{
          text-align: center;
          @include font("Prata",30px,400);
          @include adaptiv-font(30, 18);
          color: $black;
          margin-bottom: 12px;
          letter-spacing: 0.3em;
          text-transform: uppercase;
          @media (min-width: 320px) and (max-width: 800px){
            text-align: start;
          }
        }
        p{
          @include font("Prata",16px,400);
          @include adaptiv-font(16, 14);
          color: $black;
          line-height:29px;
          margin-bottom: 5px;
          text-indent: 40px;
          @media (min-width: 320px) and (max-width: 800px){
            text-indent: 20px;
          }
        }

        &_consultancy{
          margin-right: 20px;
          @media (min-width:320px) and (max-width: 800px) {
            margin-right: 0;
          }
          h1{
            position: relative;
          }
        }
        &_estimation{
          margin-left: 52px;
          @media (min-width:320px) and (max-width: 800px) {
            margin-left: 0;
          }
          h1{
            position: relative;
          }
        }
        &_buyout{
          margin-right: 20px;
          @media (min-width:320px) and (max-width: 800px) {
            margin-right: 0;
          }
        }
      }
      &__ellipses{
        display: none;
        position: absolute;
        width: 100%;
        max-width: 92px;
        left: 30%;
        top:10px;
        .ellipses__item{
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: black;
        }
        @media (min-width: 601px) and (max-width: 800px) {
          display: flex;
          justify-content: space-between;
          max-width: 140px;
        }
        @media (min-width: 320px) and (max-width: 600px) {
          display: flex;
          justify-content: space-between;
          max-width: 92px;
        }
      }
    }
    &_reverse{
      @media (min-width: 320px) and (max-width: 800px){
        flex-direction: column-reverse;
        .card__img{
          align-self: start;
        }
      }
    }
  }
  &__img{
    margin-top: 56px;
    position: relative;
    width: 100%;
    &:before{
      content: "";
      display: block;
      padding-top: 28%;
    }
    @media (min-width: 320px) and (max-width: 900px){
      margin-top: 32px;
    }
  }
  &__steps{
    margin-top: 60px;
    max-width: 1034px;
    width: 100%;
    @media (min-width: 320px) and (max-width: 900px){
      margin-top: 10px;
    }
  }
  &__btn{
    margin-top: 106px;
    @media (min-width: 320px) and (max-width: 900px){
      margin-top: 42px;
    }
  }
}
</style>