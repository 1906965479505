<template>
  <div class="main">
    <div
      class="dropzone-container"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        multiple
        name="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
        ref="file"
        accept=".pdf,.jpg,.jpeg,.png"
      />

      <label for="fileInput" class="file-label">
        <div v-if="isDragging">Release to drop files here.</div>
        <div v-else>Drop files here or <u>click here</u> to upload.</div>
      </label>
      <div class="preview-container mt-4" v-if="photos.length">
        <div v-for="file in photos" :key="file.name" class="preview-card">
          <div>
            <img class="preview-img" :src="generateURL(file)" />
            <p class="file__name">
              {{ file.name }}
            </p>
          </div>
          <div>
            <button
              class="remove_btn"
              type="button"
              @click="remove(photos.indexOf(file))"
              title="Remove file"
            >
              <b>×</b>
            </button>
          </div>
    </div>
  </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "Upload-component",
  data() {
    return {
      isDragging: false,
    };
  },
  computed:{
    ...mapState({
      photos:(state)=>state.photos,
    }),

  },
  methods: {
    ...mapMutations({
      setPhoto:"SET_PHOTOS",
    }),
    onChange() {
      this.setPhoto([...this.$refs.file.files]) ;
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    remove(i) {
      this.photos.splice(i, 1);
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.dropzone-container {
  width: 80%;
  min-height: 250px;
  height: 100%;
  border-radius: 5px;
  box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: rgba(238, 238, 238, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10px;
  flex-direction: column;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
  color: rgba(32, 32, 32, 0.5);
  @include font("Prata",12px,400);
  @include adaptiv-font(20, 16);
  margin-top: 20px;
}

.preview-container {
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
  @media (min-width: 320px) and (max-width: 530px){
    align-content: center;
    flex-direction: column;
  }
}

.preview-card {
  display: flex;
  border-radius: 6px;
  padding: 5px;
  margin-left: 5px;
}

.preview-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.remove_btn{
  border: none;
  border-radius: 6px;
  color: rgba(32, 32, 32, 0.5);
}

.file__name{
  color: rgba(32, 32, 32, 0.5);
  @include font("Prata",12px,400);
  @include adaptiv-font(20, 12);
}

</style>