<template>
  <div class="question">

  </div>
</template>

<script>

export default {
  name: "About-view",

};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.question{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:141px 5% 0 5%;
  @media (min-width: 320px) and (max-width: 1182px){
    padding:26px 18px 0 18px;
  }

}
</style>