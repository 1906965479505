<template>
<router-link class="logo" to="/">
  <img alt="logo" src="~@/assets/svg/logo.svg" />
</router-link>
</template>

<script>
export default {
  name: "logo-item"
};
</script>

<style lang="scss" scoped>

</style>