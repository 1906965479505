import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.config.productionTip = false;
Vue.use(VueAxios, axios)
Vue.use(VueYandexMetrika, {
  id: 94138785,
  router: router,
  env: process.env.NODE_ENV
  // other options
})


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

