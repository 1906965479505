<template>
  <div>
    <div class="checkbox-form">
      <div class="answers">
        <label
          v-for="option in options"
          :key="option.id"
          class="item"
        >
          <input
            :id="option.id"
            v-model="checked"
            type="radio"
            :checked="option.checked"
            :value="option.name"
            @change="onChange"
            :name="name"
          >
          <span class="label" :for="option">{{ option.label }}</span>
          <span class="checkmark" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkbox-component",
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    name:String
  },
  data(){
    return {
      checked: this.options[0].name
    };
  },
  methods: {
    onChange() {
      this.$emit('input', this.checked);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.checkbox-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  .answers {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .item {
    position: relative;
    margin-bottom: 38px;
    cursor: pointer;
    @include font("Prata",16px,400);
    @include adaptiv-font(16, 14);
    color: $light-black;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    &:hover{
      color: $black;
      .checkmark {
        background-image: url('@/assets/img/checkbox.png');
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

    }
    input:checked ~ .checkmark{
        background-image: url('@/assets/img/checkbox.png');
    }
    input:checked ~ .label{
      color: $black;
    }
  }
  .checkmark {
    position: absolute;
    top: 6px;
    left: 198px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: $dark-white;
    box-shadow: $checkbox-shadow;
  }
}



</style>