<template>
<div class="steps-contact">
  <div class="steps-contact__box">
    <div class="steps-contact__round">
    </div>
    <div class="steps-contact__text">
      category
    </div>
  </div>
  <div class="steps-contact__line"></div>
  <div class="steps-contact__box">
    <div class="steps-contact__round">
    </div>
    <div class="steps-contact__text">
      details
    </div>
  </div>
  <div class="steps-contact__line"></div>
  <div class="steps-contact__box">
    <div class="steps-contact__round">
    </div>
    <div class="steps-contact__text">
      photos
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "StepsContact"
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.steps-contact{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  &__box{
    position: relative;
  }
  &__round{
   border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: $middle-gray;
    margin: 0 10px 0 10px;
    opacity: 0.2;
  }
  &__text{
    position: absolute;
    @include font("Pontano",11px,400);
    letter-spacing: 0.355em;
    text-transform: uppercase;
    color: $black;
    left: -11px;
    bottom: -18px;
  }
  &__line{
    width: 38%;
    height: 1px;
    background-color: $dark-black;
  }
}
</style>