<template>
<div class="modal" :class="opened ? '' : 'modal_hide' ">
  <div class="modal__wrapper">
    <div class="modal__text">{{ status }}</div>
    <div class="modal__icon">
      <img v-if="status==='success!'" alt="photo" src="@/assets/img/success.png">
      <img v-else alt="photo" src="@/assets/svg/error.svg">
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from "vuex";


export default {
  name: "modal-component",
  computed:{
    ...mapState({
     opened:(state)=>state.open,
     status:(state)=>state.status
    }),

  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.modal{
  max-width: 346px;
  width: 100%;
  height: 122px;
  position: fixed;
  background-color: $white;
  box-shadow: $body-shadow;
  border-radius: 20px;
  right: 10px;
  bottom: 10px;
  display: flex;
  opacity: 1;
  animation: end 7.5s forwards;

  &__wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text{
    @include font("Pontano",26px,400);
    letter-spacing: 0.365em;
    color: $black;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    max-width: 274px
  }
  &__icon{
    height: 30px;
    width: 30px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.modal_hide{
    display: none;
  opacity: 0;

}
@keyframes start {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes end {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
</style>