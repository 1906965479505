<template>
<div class="card-img"  :style="`--my-src: url(${url})`" :class="objectStyle">
<!--  <img alt="photo" :src="url"/>-->
</div>
</template>

<script>
export default {
  name: "ImageCard",
  props:{
    url:String,
    full:Boolean,
    styleType:String
  },
  computed: {
    objectStyle() {
      return {
        "card-img_round": this.styleType === 'round',
        "card-img_square": this.styleType === 'none',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";
.card-img{
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 52px;
  box-shadow: inset 3px 4px 0px rgba(0, 0, 0, 0.55);
  background-image: var(--my-src);
  background-repeat: no-repeat;
  background-position: 50% 14%;
  background-size: cover;
  &_round{
    border-radius: 19px;
  }
  &_square{
    border-radius: 0;
    box-shadow: none;
  }
}
</style>