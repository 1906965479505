<template>
  <div class="header">
   <div class="header__section">
     <div class="header__logo">
       <Logo/>
     </div>
     <div class="header__contain">
       <div class="header__nav" :class="visible ? 'header__nav_menu':''">
         <button
           class="header__btn"
           type="button"
           title="Remove file"
           @click="closeNav"
         >
           <b>×</b>
         </button>
         <Link  class="header__link" v-for="item in menu" :active="currentPage.id" :key="item.id" :id="item.id" :title="item.title" :path="item.path" @click="onCurrent"   />
       </div>
       <div class="header__menu" @click="openNav">
         <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"/></svg>
       </div>
       <div class="header__line"></div>
     </div>
   </div>
    <Preview  :url="currentPage.src" :title="currentPage.title" :styleType="currentPage.style"/>
  </div>

</template>

<script>
import Logo from "@/ui/Logo.vue";
import Link from "@/ui/Link.vue";
import { mapState } from "vuex";
import Preview from "@/ui/Preview.vue";

export default {
  name: "header-item",
  components: { Preview, Link, Logo },
  data:()=>({
    currentId:"",
    visible:false,
  }),
  computed:{
    ...mapState({
      menu:(state)=>state.menu
    }),
    currentPage(){
      return this.menu.find((el)=>{
        return el.path === this.$route.path
      })
    }
  },
  methods: {
    onCurrent (data) {
      this.currentId=data
      this.visible=false
    },
    openNav(){
      this.visible=true
    },
    closeNav(){
      this.visible=false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles.scss";

.header{
  width: 100%;
  margin-bottom: 10px;
  @media (min-width: 320px) and (max-width:800px){
    margin-bottom: 9px;
  }
  &__logo{
    width: 146px;
    margin-top: 20px;
    margin-right: 14px;
    @media (min-width: 601px) and (max-width: 800px){
      width: 12%;
    }
    @media (min-width: 320px) and (max-width: 600px){
      width: 88px;
    }
  }
  &__section{
    display: flex;
    height: 106px;
    margin-bottom: 74px;
    @media (min-width: 320px) and (max-width: 800px){
      height: 80px;
      margin-bottom: 30px
    }
  }
  &__contain{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: end;
    justify-content: end;
  }
  &__search{
    margin-bottom: 40px;
  }
  &__menu{
    display: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    svg{
      width: 100%;
      height: 100%;
    }
    @media (min-width: 320px) and (max-width: 800px){
      display: block;
    }
  }
  &__nav{
    max-width: 486px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    @media (min-width: 320px) and (max-width: 800px){
      display: none;
    }
  }
  &__line{
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #000000 56.33%, rgba(0, 0, 0, 0) 103.21%);
    @media (min-width: 320px) and (max-width: 800px){
      display: none;
    }
  }
  .header__nav_menu{
    display: flex;
    position: fixed;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    justify-content: start;
    max-width: none;
    width: 100%;
    align-items: center;
    top:0;
    left: 0;
    z-index: 100;
    background: #FCFCFC;
    padding: 10px;
    box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.25);
    .header__link{
      margin-top: 60px;
    }
  }
  &__btn{
    display: none;
    @media (min-width: 320px) and (max-width: 800px){
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      border: none;
      border-radius: 6px;
      color: rgba(32, 32, 32, 0.5);
      align-self: end;
    }
  }

}

</style>